import React from "react"
import Fade from "react-reveal/Fade"
import BodyTitleContent from "../utils/title-content"

const Expertise = ({ area }) => {
  return (
    <section>
      <h3 className="md:text-[26px] text-[21px]">{area.heading}</h3>
      <ul>
        {area.items.map((item, index) => {
          return <li key={index}>{item}</li>
        })}
      </ul>
    </section>
  )
}
const OurExpertise = () => {
  const areas_of_expertise = [
    {
      heading: "Branding",
      items: [
        "Brand Strategy & Positioning",
        "Visual Identity",
        "Brand Architecture",
        "Naming & Verbal Identity",
        "Brand Guidelines",
      ],
    },
    {
      heading: "Product Design",
      items: [
        "User Interface & Experience",
        "Design Systems & Style Guides",
        "Web Development",
        "Naming & Verbal Identity",
        "Mobile Applications",
      ],
    },
    {
      heading: "Brand Engagement",
      items: [
        "Campaign & Ad Development",
        "Publications & Editorial Design",
        "Event & Environmental Branding",
        "Social Media Communication",
        "Design Production & Execution",
        "Brand Management (Retainer)",
      ],
    },
    {
      heading: "Content",
      items: [
        "Content Strategy",
        "Data Visualization",
        "Creative Direction",
        "Motion & Graphic Design",
        "Copy Writing",
      ],
    },
  ]

  const show_areas_of_expertise = areas_of_expertise.map((area, index) => (
    <Expertise area={area} key={index} />
  ))
  return (
    <Fade>
      <header className="sm:pr-20 pr-8 ">
        <section className={`bg-black text-white sm:py-20 py-8`}>
          <BodyTitleContent>
            <>
              <p>
                We believe organizations can achieve a lot more if their brand
                communication embodies who they are and is endearing to their
                people. We come with the external perspective and expertise but
                it all begins with your mission and there is only so much we can
                do alone.
              </p>

              <p>
                We call it “true partnership” because that’s what it is, and to
                us this means more than another great-sounding value
                proposition.
              </p>
            </>
          </BodyTitleContent>

          <BodyTitleContent heading="Our Expertise">
            <div className=" w-full lg:mt-0 mt-6 grid sm:grid-cols-2 grid-cols-1 gap-x-4 gap-y-12">
              {show_areas_of_expertise}
            </div>
          </BodyTitleContent>

          {/* <section className="flex sm:flex-row flex-col sm:mt-20 mt-8">
            <div className="sm:w-3/12 w-full">
              <h2>Our Expertise</h2>
            </div>
          </section> */}
        </section>
      </header>
    </Fade>
  )
}

export default OurExpertise
