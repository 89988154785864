import React from "react"

import ViewProjectArrow from "../icons/view-project-arrow"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const WorkPreview = ({ work, navigation }) => {
  const title = work.title
  const excerpt = work.workFields.introHeading
  const image =
    work.featuredImage.node.localFile.childImageSharp.gatsbyImageData
  // const url = title.replace(/ /g, "-").toLowerCase()
  const url = work.slug

  const image_style = `${
    navigation
      ? "md:h-[300px] h-[130px]"
      : "xl:h-[550px] sm:h-[400px]   h-[200px]"
  }  object-cover object-center`

  const link_style = `group work-preview flex ${
    navigation
      ? "lg:odd:pl-0 odd:pl-8 lg:even:pl-[55px] lg:even:flex-row  lg:odd:flex-row-reverse lg:mb-0 mb-6"
      : " lg:odd:pl-[55px]  lg:even:pl-0  odd:pl-8   lg:odd:flex-row  lg:even:flex-row-reverse lg:mb-0 mb-14 "
  }   cursor-pointer flex-col-reverse lg:even:pr-0 even:pr-8   hover:opacity-70`

  const article_style = `${
    navigation ? "lg:mt-12 mt-4 " : "lg:mt-24 mt-4 "
  } lg:w-3/12 lg:group-odd:pl-[25px] pr-8 view-project`
  return (
    // <div>Work preview</div>

    <Link to={`/works/${url}`} className={link_style}>
      <article className={article_style}>
        <h2>{title}</h2>
        <p
          className="-mt-2 md:mb-6 mb-2"
          dangerouslySetInnerHTML={{ __html: excerpt }}
        ></p>

        {navigation ? null : (
          <div className="flex items-center">
            <p className="text-base font-semibold min-w-max mb-0 mt-0">
              View Project
            </p>
            <span className={`transition-transform`}>
              <ViewProjectArrow />
            </span>
          </div>
        )}
      </article>
      <div className="lg:w-9/12 overflow-hidden ">
        <GatsbyImage image={image} alt={title} className={image_style} />
      </div>
    </Link>
  )
}

export default WorkPreview
