import React from "react"

import Layout from "../components/layout"
import PageHeader from "../components/utils/page-header"
import BodyTitleContent from "../components/utils/title-content"
import Magic from "../components/utils/magic"
import OurExpertise from "../components/works/expertise"
import HomeWorks from "../components/home/works"

const OurWorks = () => {
  const seo = {
    title: `Our Works`,
    description: `We see design as a tool to create coherence and strengthen an idea
          across a brand’s diversity of expressions, such that people—via
          different mediums and experiences—can easily identify the brand and
          potentially become fans.`,
  }
  return (
    <Layout seo={seo}>
      <PageHeader
        dark={false}
        text={
          <>
            Strategy-led design <br className="lg:block hidden" />
            for transformation
          </>
        }
      />

      <OurExpertise />

      <BodyTitleContent
        heading="Systems, not elements"
        content={
          <p>
            We guide businesses on the path to becoming brands, making it
            crystal clear that true transformation goes beyond the quality of
            brand assets and narratives.
          </p>
        }
      />
      <BodyTitleContent
        heading="A process that delivers"
        content={
          <p>
            Our bespoke approach is for clients who think long-term and care
            about sustainability. Beyond brand strategy and identity design, we
            also go all the way to empower the client with step-by-step guides,
            toolkits and systems to help them fulfill their long-term goals as a
            brand.
          </p>
        }
      />

      <BodyTitleContent>
        <h2 className="font-light"> Recent Works</h2>
      </BodyTitleContent>
      <section className="-mt-16">
        <HomeWorks />
      </section>

      <Magic />
    </Layout>
  )
}

export default OurWorks
